import "bootstrap-sass/assets/javascripts/bootstrap/tooltip";
import "bootstrap-sass/assets/javascripts/bootstrap/modal";
import * as Utils from "./Components/Utils";

import "./Components/IncreaseNumberSlider";
import "./Components/CaseStudiesCarousel";
import "./Components/Timeline";
import "./Components/Parallax";
import "./Components/StickyHeader";
import "./Components/CustomSelect";
import "./Components/Nav";
import "./Components/News";
import "./Components/ExpandableCard";
import "./Components/CookiePopup";
import "./Components/IC-Reports";
import "./Components/HeaderResize";
import "./Components/Forms/SubscribeForm";
import "fv-analytics";

if($("#container")[0].scrollHeight > $(window).height()) {
    $(".sticky-header-stick").css('width', 'calc(100% - ' + Utils.getScrollBarWidth() + 'px)');
}

$(document).ready(function() {
    const $page = $('.Page');
    const sameClassHeight = ['BoxComponent_ITTC_Halves', 'ExpandableCard', 'SameHeightElem', 'BoxComponent_TITC'];

    $("body").imagesLoaded( function() {
        FixHeights();
    });

    let resizeTimer;
    $(window).resize(function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            resizeCards();
        }, 250);
    });

    function resizeCards() {
        let classHeights = {};
        for(var i=0; i<sameClassHeight.length; i++) {
            let $thisClass = $("." + sameClassHeight[i]);
            classHeights[sameClassHeight[i]] = 0;
            $thisClass.removeStyle('height');
        }
        FixHeights();
    }

    function FixHeights() {
        let classHeights = {};
        for(var i=0; i<sameClassHeight.length; i++) {
            let $thisClass = $("." + sameClassHeight[i]);
            classHeights[sameClassHeight[i]] = 0;
            $thisClass.each(function() {
                let $this = $(this);
                let height = $this.height();
                if(height > classHeights[sameClassHeight[i]]) {
                    classHeights[sameClassHeight[i]] = height;
                }
            });
            $thisClass.height(classHeights[sameClassHeight[i]] - 1);
        }
    }

    $(".NumberedToggleable .HeaderContent").click(function() {
        let $item = $(this).closest('.NumberedToggleable');

        if($item.hasClass('active')) {
            $item.removeClass('active');
            $item.find('.InnerContent').slideUp();
            $item.find('i.fa').removeClass('fa-rotate-180');
        } else {

            $(".NumberedToggleable").each(function() {
                $(this).removeClass('active');
                $(this).find('.InnerContent').slideUp();
                $(this).find('i.fa').removeClass('fa-rotate-180');
            });

            $item.addClass('active');
            $item.find('.InnerContent').slideDown();
            $item.find('i.fa').addClass('fa-rotate-180');
        }
    });

    $(".videoPlayer").click(function() {
        var $this = $(this);
        if(!$this.hasClass('videoStarted')) {
            $this.addClass('videoStarted');
            $this.attr('controls', 'true');
            $this[0].play();
        }
    });

    $("h1, h2, h3, h4, h5, h6").each(function() {
        var text = $(this).html();
        var emails = text.match(/(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi);
        if(emails) {
            for(var i=0; i<emails.length; i++) {
                text = text.replace(emails[i], '<a href="mailto:'+emails[i]+'">'+emails[i]+'</a>');
            }
            $(this).html(text);
        }
    });
});
