const container = document.querySelector('#container');
const stickyHeader = document.querySelector('.sticky-header');

const setHeaderHeight = () => {
    const header = document.querySelector('.Header');
    const {height} = stickyHeader.getBoundingClientRect();
    header.style.paddingTop = `${height}px`;
};

if (stickyHeader) {
    setHeaderHeight();

    container.addEventListener('resize', setHeaderHeight);

    container.addEventListener('scroll', () => {
        const header = document.querySelector('.Header');
        const {height} = stickyHeader.getBoundingClientRect();

        if (container.scrollTop >= height) {
            header.style.paddingTop = `${height}px`;
            stickyHeader.style.backgroundColor = 'rgba(255, 255, 255, 1)';
        } else {
            stickyHeader.style.backgroundColor = 'rgba(255, 255, 255, .5)';
        }
    });
}
