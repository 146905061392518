var $mainNav = $(".MainNav");
var $MainNavItem  = $(".MainNavItem");
var $mobileNav = $(".MobileNav");
$(".NavToggle, .CloseNav").click(function() {
	$mobileNav.find('nav').toggleClass('navActive');
});

$mainNav.find(".SubNav").hover(function() {
	if(!$(this).hasClass('active')) {
		$(".SubNav").removeClass('active');
		$(".SubNavigation").hide();

		$(this).addClass('active');
		slideSubNav($(this).data('target'));
	}
});

// $MainNavItem.mouseleave(function(e) {
// 	if(!e.toElement.classList.contains('SubNav') && !e.toElement.classList.contains('SubNavWrap')) {
// 		$(".SubNav").removeClass('active');
// 		$(".SubNavigation").hide().removeClass('active');
// 		showDefaultNav();
// 	}
// });

$('.SubNavItem').on('click', function (e) {
    showDefaultNav();
});

$mainNav.find(".SubNavigation").mouseenter(function() {
	var $target = $(this).attr('id');
	$('.SubNav[data-target="'+$target+'"]').addClass('active');
});

$mainNav.find(".SubNavigation").mouseleave(function() {
	var $target = $(this).attr('id');
	$('.SubNav[data-target="'+$target+'"]').removeClass('active');
	if(!$(this).hasClass('active-current')) {
		$(this).hide().removeClass('active');
		showDefaultNav();
	}
});

$mobileNav.find(".SubNav").click(function() {
	var target = $(this).data('target');
	var $nav = $mobileNav.find('#'+target);
	$nav.toggleClass('active');
	if($nav.hasClass('active-current')) {
		return;
	}
	if($nav.hasClass('active')) {
		$mobileNav.find('.SubNavigation.active').not('.active-current').slideUp();
		$mobileNav.find('.SubNavigation.active').removeClass('active');
		$nav.addClass('active');
		$nav.slideDown();
	} else {
		$nav.slideUp();
	}
});

function showDefaultNav(instant) {
	if(typeof instant == 'undefined') {
		instant = false;
	}

	var id = $(".SubNavigation.active-current").attr('id');
	slideSubNav(id, instant);
}

showDefaultNav(true);

function slideSubNav(target, instant) {
	var $target = $("#" + target );

	$target.show().removeStyle('width');
	var width = $target.width() + 2;
	$target.find('.SubNavItem').css('opacity', '0');
	if(!instant) {
		$target.width(0).addClass('active').animate({'width': width}, 'normal');
		$target.find('.SubNavItem').animate({'opacity': '1'});
	} else {
		$target.width(0).addClass('active').css('width', width);
		$target.find('.SubNavItem').css({'opacity': '1'});
	}
}
