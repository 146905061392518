(function () {
    const marginPercentage = 11.52694610778443;
    const header = document.querySelector('.Header');
    const main = document.querySelector('.Main');
    const triangle = document.querySelector('.HeaderSection__triangle');
    if(main && triangle) {
        const ratio = 0.2991913746630728;

        const update = () => {
            const {width} = main.getBoundingClientRect();
            triangle.style.borderWidth = `${Math.floor(width * ratio)}px ${width}px 0 0`;

            // TODO: Prevent overlapping header

            const mainBox = main.getBoundingClientRect();
            const mainHeight = triangle.getBoundingClientRect().height + mainBox.height + mainBox.top;
            const headerHeight = header.getBoundingClientRect().height;

            if (headerHeight < mainHeight) {
                header.style.height = `${mainHeight + ((100 / mainHeight) * marginPercentage)}px`;
            }
        };

        update();

        window.addEventListener('resize', update);
    }
})();