import React, {Component} from "react";
import {render} from "react-dom";
import PropTypes from "prop-types";
import Slider from "react-slick";

class CaseStudiesCarousel extends Component {

    getSlides() {
        return this.props.slides.map(({title, brief, content, quote, image, author}, index) => (
            <div
                key={index}
            >
                <div className="CaseStudiesCarousel__slide">
                    <div className="CaseStudiesCarousel__slide-inner">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="CaseStudiesCarousel__slide-title">
                                        {title}
                                    </div>
                                    <br />
                                    <div className="CaseStudiesCarousel__slide-brief">
                                        {brief}
                                    </div>
                                    <br />
                                    <br />
                                    <div className="CaseStudiesCarousel__slide-content" dangerouslySetInnerHTML={{__html: content}} />
                                </div>
                                <div className="col-md-7">
                                    <br />
                                    <div className="CaseStudiesCarousel__slide-quote">
                                        {quote}
                                    </div>
                                    <div className="CaseStudiesCarousel__slide-image">
                                        <div className="CaseStudiesCarousel__author" dangerouslySetInnerHTML={{__html: author}} />
                                        <img className="img-responsive" src={image} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }

    render() {
        return (
            <div className="CaseStudiesCarousel">
                <div className="CaseStudiesCarousel__inner">
                    <Slider
                        infinite={true}
                        autoplay={true}
                        autoplaySpeed={8000}
                    >
                        {this.getSlides()}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default CaseStudiesCarousel;

CaseStudiesCarousel.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

$('[data-component=CaseStudiesCarousel]').each(function() {
    const $node = $(this);
    const params = $node.data('params');
    render(<CaseStudiesCarousel {...params} />, this);
});
