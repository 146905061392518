const $page = $(".Page");
const section = document.querySelector(".ExpandableCardsSection");

let allowClick = false;

$("body").imagesLoaded(function () {
    allowClick = true;
});

$(".ExpandableCard .Photo").click(function () {
    if (!allowClick) return;

    let $card = $(this).closest(".ExpandableCard");
    if (!$card.hasClass("active")) {
        $card.find(".Expander").click();
    } else {
        $card.find(".Contractor").click();
    }
});

$(".ExpandableCard .Expander").click(function () {
    if (!allowClick) return;

    console.log("add padding...");
    section.style.paddingBottom = "30rem";

    const $parentRow = $(this).closest(".row");
    const $cardsLength = $parentRow.children().length;

    let $card = $(this).closest(".ExpandableCard");

    /* Get adjustment height by setting classes / styles */
    $card.addClass("active");
    let adjustHeight =
        $card.css("position") != "relative" && $cardsLength > 1 ? true : false;
    let unexpandedHeight = $card.outerHeight();
    $card.find(".Expander").hide();
    $card.find(".Content").show();
    let expandedHeight = $card.outerHeight();
    $card.find(".Expander").show();
    $card.find(".Content").hide();
    let adjustmentHeight = expandedHeight - unexpandedHeight;
    $card.removeClass("active");
    /* Get adjustment height by setting classes / styles */

    if ($(".ExpandableCard.active").length) {
        let currentPadding = $page.css("paddingBottom").replace("px", "");

        let $thisCard = $(".ExpandableCard.active");
        $thisCard.find(".Expander").slideDown();
        $thisCard.find(".Information .Content").slideUp({
            duration: "normal",
            complete: function () {
                $thisCard.removeClass("active");
                $card.addClass("active");
                if ($cardsLength == 1) {
                    $card.addClass("singleCard");
                }
                $card.find(".Expander").slideUp();
                $card.find(".Information .Content").slideDown("normal");
                if (adjustHeight) {
                    $page.animate(
                        { "padding-bottom": adjustmentHeight },
                        "normal"
                    );
                }
                if ($cardsLength == 1) {
                    $page.animate({ "padding-bottom": 0 }, "normal");
                }
                $card.find(".Contractor").slideDown();
            },
        });
        if (adjustHeight) {
            $page.animate({ "padding-bottom": 0 }, "normal");
        }
        $thisCard.find(".Contractor").slideUp();
    } else {
        $card.addClass("active");
        if ($cardsLength == 1) {
            $card.addClass("singleCard");
        }
        $card.find(".Expander").slideUp();
        $card.find(".Information .Content").slideDown("normal");
        if (adjustHeight) {
            $page.animate({ "padding-bottom": adjustmentHeight }, "normal");
        }
        if ($cardsLength == 1) {
            $page.animate({ "padding-bottom": 0 }, "normal");
        }
        $card.find(".Contractor").slideDown();
    }
});

$(".ExpandableCard .Contractor").click(function () {
    if (!allowClick) return;

    setTimeout(() => {
        console.log("remove padding");
        section.style.paddingBottom = 0;
    }, 400);

    let $card = $(this).closest(".ExpandableCard");
    $card.find(".Expander").slideDown();
    $card.find(".Information .Content").slideUp({
        duration: "normal",
        complete: function () {
            $card.removeClass("active");
        },
    });
    $page.animate({ "padding-bottom": 0 }, "normal");
    $card.find(".Contractor").slideUp();
});
