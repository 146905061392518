
const parallax = document.querySelector('.parallax');

if (parallax) {
	const container = document.querySelector('#container');
	const VELOCITY = 1.4;

	var fullHeight = 0;
	$("#container").children().each(function() {
		fullHeight += $(this).outerHeight();
	});

	if(fullHeight > 1200) {
        $(window).scroll(() => {
			const {scrollTop, scrollHeight, clientHeight} = document.querySelector('html');
			const availableScroll = Math.abs(clientHeight - scrollHeight);
			const scrolledPercentage = (100 / availableScroll) * scrollTop;
			const offsetY = Math.min(100, scrolledPercentage * VELOCITY);
			parallax.style.backgroundPosition = `50% ${offsetY}%`;
		});
	}
}
