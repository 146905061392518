import React, {Component} from "react";
import {render} from "react-dom";
import PropTypes from "prop-types";

class IncreaseNumber extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentNumbers: {},
            currentDecimalNumbers: {},
        };
    }

    componentDidMount() {
        this.reset();
        if (this.props.autoPlay) {
            this.animate();
        }
    }

    // set back to initial state
    reset(callback) {
        this.setState({currentNumbers: $.extend({}, this.props.numbersFrom)}, callback);
        this.setState({currentDecimalNumbers: $.extend({}, this.props.decimalNumbersFrom)}, callback);
    }

    /**
     * !!! or reanimate
     */
    animate() {

        this.reset(() => {

            const {duration} = this.props;
            const frequency = IncreaseNumber.UPDATE_FREQUENCY;
            let totalSteps;

            if(Object.keys(this.state.currentDecimalNumbers).length === 0){
                totalSteps = duration / frequency;
            }else{
                totalSteps = duration / frequency * 2;
            }
            const intervalId = window.setInterval(() => {
                const newCurrentNumbers = $.extend({}, this.state.currentNumbers);
                const newCurrentDecimalNumbers = $.extend({}, this.state.currentDecimalNumbers);

                const {numbersFrom, numbersTo, decimalNumbersFrom, decimalNumbersTo} = this.props;
                for (let numberKey in newCurrentNumbers) {
                    newCurrentNumbers[numberKey] += (numbersTo[numberKey] - numbersFrom[numberKey]) / totalSteps;
                    if (newCurrentNumbers[numberKey] >= numbersTo[numberKey]) {
                        newCurrentNumbers[numberKey] = numbersTo[numberKey];
                        window.clearInterval(intervalId);
                    }
                }
                for (let numberKeyDecimal in newCurrentDecimalNumbers) {
                    newCurrentDecimalNumbers[numberKeyDecimal] += (decimalNumbersTo[numberKeyDecimal] - decimalNumbersFrom[numberKeyDecimal]) / totalSteps;
                    if (newCurrentDecimalNumbers[numberKeyDecimal] >= decimalNumbersTo[numberKeyDecimal]) {
                        newCurrentDecimalNumbers[numberKeyDecimal] = decimalNumbersTo[numberKeyDecimal];
                        window.clearInterval(intervalId);
                    }
                }
                this.setState({currentNumbers: newCurrentNumbers});
                 this.setState({currentDecimalNumbers: newCurrentDecimalNumbers});

            }, frequency);

        });
    }

    getText() {
        const {template, decimalNumbersTo} = this.props;
        const {currentNumbers, currentDecimalNumbers} = this.state;
        // console.log('currentDecimalNumbers', currentDecimalNumbers);

        let output = template;
        for (let numberKey in currentNumbers) {
            const numberValue = Number(Math.ceil(currentNumbers[numberKey])).toLocaleString();

            output = output.replace(`{${numberKey}}`, numberValue);
        }
        if (typeof decimalNumbersTo !== "undefined") {
            for (let numberKeyDecimal in currentDecimalNumbers) {
                // console.log('numberKey2',numberKeyDecimal);
                const numberDecimalValue = Number(Math.ceil(currentDecimalNumbers[numberKeyDecimal])).toLocaleString();
                output = output.replace(`{${'valueDecimal'}}`, numberDecimalValue);

            }
        }
        return output;
    }

    render() {
        return (
            <span className="IncreaseNumber" dangerouslySetInnerHTML={{__html: this.getText()}}/>
        );
    }
}

IncreaseNumber.UPDATE_FREQUENCY = 10; // ms

export default IncreaseNumber;

IncreaseNumber.propTypes = {
    template: PropTypes.string.isRequired,
    numbersFrom: PropTypes.objectOf(PropTypes.number).isRequired,
    numbersTo: PropTypes.objectOf(PropTypes.number).isRequired,
    decimalNumbersFrom: PropTypes.objectOf(PropTypes.number),
    decimalNumbersTo: PropTypes.objectOf(PropTypes.number),
    duration: PropTypes.number.isRequired,
    autoPlay: PropTypes.bool, // start immediately after did mount
};

IncreaseNumber.defaultProps = {
    autoPlay: false,
};

$('[data-component=IncreaseNumber]').each(function () {
    const $node = $(this);
    const params = $node.data('params');
    render(<IncreaseNumber {...params} />, this);
});
