import React, {Component, Fragment} from "react";
import {render} from "react-dom";
import "isomorphic-fetch";

class CookiePopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            showSettings: false,
            form: {
                functional: true,
                performance: true,
                advertising: false
            }
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
    }

    onChange(event) {
        const {form} = this.state;
        form[event.target.name] = !form[event.target.name];
        this.setState({form});
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({open: !this.state.open});

        const request = {
            form: this.state.form
        };

        fetch(window.APP.url + '/api/cookie-settings', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': window.APP.csrf_token
            },

            body: JSON.stringify(request)
        })
            .then(response => {
                response.json().then(data => {
                    if (response.status !== 201) {
                        console.warn('An error has occurred');
                    }else{
                        let domainArr = window.location.hostname.split('.');
                        if(domainArr[0] == 'consumer' || domainArr[0] == 'retailer' || domainArr[0] == 'serviceprovider') domainArr.shift();
                        let domain = domainArr.join('.');

                        this.setCookie('cookie-settings', data, 365, domain);
                    }
                });
            })
            .catch(function (error) {
                console.warn('Request failed', error);
            });
    }

    setCookie(cname, cvalue, exdays, domain) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + domain;
    }

    toggleSettings(event) {
        event.preventDefault();
        this.setState({showSettings: !this.state.showSettings}, () => {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }


    render() {
        let closed, overflow = '';
        if(!this.state.open) closed = 'closed';
        if(!this.state.showSettings) overflow = 'hidden-overflow';

        return (
            <div className={`Cookie_popup ${closed} ${overflow}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div
                                className={`${!this.state.showSettings ? 'Cookie_popup__intro' : 'Cookie_popup__intro closed'}`}>
                                <p>This site uses cookies for details of our <a href={`${url('privacy')}`}>cookie
                                    policy</a> or
                                    to update your settings <a href="#" onClick={this.toggleSettings}>click here</a>.
                                </p>
                                <button className="Cookie_popup__button" onClick={this.onSubmit}>Accept</button>
                            </div>
                            <div
                                className={`${!this.state.showSettings ? 'Cookie_popup__settings' : 'Cookie_popup__settings translate'}`}>
                                <p>We use cookies and similar technologies on our websites and mobile applications
                                    to
                                    help
                                    provide you with the best possible online experience. By using our sites and
                                    apps,
                                    you
                                    agree that we may store and access cookies and similar technologies on your
                                    device.
                                    You can <a href={`${url('privacy')}`}>find out more</a> and set your own preferences
                                    here.</p>
                                <form className="Cookie_popup__form" onSubmit={this.toggleSettings}>
                                    <div className="toggle">
                                        <span className="toggle__label">Essential</span>
                                        <i className="fa fa-info-circle" aria-hidden="true"
                                           data-toggle="tooltip"
                                           data-placement="top"
                                           title="These cookies let you use the website. Without them the services cannot be provided"></i>
                                        <label className="switch">
                                            <input type="checkbox" value="necessary" checked disabled/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>

                                    {/*<div className="toggle">*/}
                                        {/*<span className="toggle__label">Functional</span>*/}
                                        {/*<i className="fa fa-info-circle" aria-hidden="true"*/}
                                           {/*data-toggle="tooltip"*/}
                                           {/*data-placement="top"*/}
                                           {/*title="These help us by remembering your preferences and settings. Such as*/}
{/*remembering if you visited the website before and you have selected a particular category to filter your selections such as a client, a customer or a retailer.*/}
{/*They also remember settings on the website like colour, font size and layout.*/}
{/*"></i>*/}
                                        {/*<label className="switch">*/}
                                            {/*<input type="checkbox" value="functional" name="functional"*/}
                                                   {/*onChange={this.onChange}*/}
                                                   {/*checked={this.state.form.functional}/>*/}
                                            {/*<span className="slider round"></span>*/}
                                        {/*</label>*/}
                                    {/*</div>*/}

                                    <div className="toggle">
                                        <span className="toggle__label">Performance</span>
                                        <i className="fa fa-info-circle" aria-hidden="true"
                                           data-toggle="tooltip"
                                           data-placement="top"
                                           title="These help us understand how people are using the website so we can monitor performance and make improvements.  Such as:
Collecting information about the number of visitors or which pages are used most often.
Making sure the website functions correctly and fix any problems.
Help to improve the use and responses of the website.
"></i>
                                        <label className="switch">
                                            <input type="checkbox" value="performance" name="performance"
                                                   onChange={this.onChange}
                                                   checked={this.state.form.performance}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>

                                    <div className="toggle">
                                        <span className="toggle__label">Advertising</span>
                                        <i className="fa fa-info-circle" aria-hidden="true"
                                           data-toggle="tooltip"
                                           data-placement="top"
                                           title="Our website does not use advertising cookies.
"></i>
                                        <label className="switch">
                                            <input type="checkbox" value="advertising" name="advertising"
                                                   onChange={this.onChange}
                                                   checked={this.state.form.advertising}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <button type="submit" className="Cookie_popup__button">Save settings</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Output to all existing component-suggestions nodes in the DOM
const nodes = document.querySelectorAll('.component-cookie-popup');
for (let i = 0; i < nodes.length; i++) {
    const props = JSON.parse(nodes[i].getAttribute('data-props') || '{}');
    render(<CookiePopup {...props}/>, nodes[i]);
}
