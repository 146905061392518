const timeline = document.querySelector('.timeline');

if (timeline) {
    const scrollDot = document.querySelector('.timeline-progress-dot');
    const instructions = document.querySelector('.timeline-instructions');
    const leftArrow = document.querySelector('.timeline-arrow--left');
    const rightArrow = document.querySelector('.timeline-arrow--right');
    
    let viewableWidth = $('.TimeLine').width();
    let scrollCurrentPercentage = 0;

    let scrollableWidth = 0;
    $(".timeline-group").each(function() {
        scrollableWidth += $(this).width();
    });

    let scrollablePercentage = 100 / (scrollableWidth / viewableWidth);

    const updateScrollDot = (percentage = null) => {
        instructions.style.display = 'none';
        const {scrollLeft, scrollWidth, clientWidth} = timeline;
        const availableScroll = Math.abs(clientWidth - scrollWidth);
        const scrolledPercentage = (100 / availableScroll) * scrollLeft;
        scrollDot.style.marginLeft = `${percentage ? percentage : scrolledPercentage}%`;
        if (percentage !== null) {
            timeline.scrollLeft = (Math.round((availableScroll / 100) * percentage));
        }

        scrollCurrentPercentage = scrolledPercentage;
    };

    instructions.addEventListener('mousedown', () => instructions.style.display = 'none');
    timeline.addEventListener('scroll', () => updateScrollDot());
    leftArrow.addEventListener('click', function() {
        scrollCurrentPercentage -= scrollablePercentage;
        if(scrollCurrentPercentage < 0) {
            scrollCurrentPercentage = 0;
        }
        updateScrollDot(scrollCurrentPercentage);
    });

    rightArrow.addEventListener('click', function() {
        scrollCurrentPercentage += scrollablePercentage;
        if(scrollCurrentPercentage > 100) {
            scrollCurrentPercentage = 100;
        }
        updateScrollDot(scrollCurrentPercentage);
    });

    $(function(){
        
        $(".timeline").attachDragger();
        $(window).scroll(function() {
            var isElementInView = $('.TimeLine').isElementInView(true);
            if (isElementInView) {
                setTimeout(function() {
                    $('.timeline-instructions').fadeOut();
                }, 2000 * 1)
            }
        });

        var $draggerItem = $(".timeline-progress-dot");
        var $draggerItem2 = $(".timeline-progress-tracker");
        var isDraggingDragger = false;
        var moveLeft = false;
        var mousePos = {x: 0, y: 0};
        var calculationWidth = $draggerItem2.width();
        var dotPercentageLeft = 0;

        $draggerItem2.on('touchmove mousemove', function(e) {
            if(isDraggingDragger) {
                var updatedObj = {x: e.offsetX, y: e.offsetY};
                moveLeft = updatedObj.x > mousePos.x ? false : true;
                mousePos = updatedObj;
                dotPercentageLeft = 100 / calculationWidth * mousePos.x;
                updateScrollDot(dotPercentageLeft);
            }
        });

        $draggerItem.on('mousedown touchdown', function() {
            isDraggingDragger = true;
        });
        $draggerItem.on('mouseup touchup', function() {
            isDraggingDragger = false;
        });
        $draggerItem2.on('mouseup touchup', function() {
            isDraggingDragger = false;
        });
        $draggerItem2.on('touchleave mouseleave', function(e) {
            if(!$(e.relatedTarget).parents('.timeline-progress').length) {
                isDraggingDragger = false;
            }
        });

        let resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
                calculationWidth = $draggerItem2.width();
                viewableWidth = $('.TimeLine').width();
                scrollablePercentage = 100 / (scrollableWidth / viewableWidth);
            }, 250);
        });
    });

    

}
