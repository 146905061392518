$('select').each(function(){
	var $this = $(this),
		numberOfOptions = $(this).children('option').length;

	$this.addClass('select-hidden');
	$this.wrap('<div class="select"></div>');
	$this.after('<div class="select-styled"></div>');

	var $styledSelect = $this.next('div.select-styled');
	$styledSelect.text($this.children('option').eq(0).text());

	var $list = $('<ul />', {
		'class': 'select-options'
	}).insertAfter($styledSelect);

	var selectedVal = false;

    if ($('.meeting').length > 0) {
        selectedVal = '2021';
    }

	for (var i = 0; i < numberOfOptions; i++) {
		var $thisOption = $this.children('option').eq(i);
		selectedVal = $thisOption.attr('selected') ? $thisOption.val() : selectedVal;

		$('<li />', {
			text: $thisOption.text(),
			rel: $thisOption.val(),
		}).appendTo($list);
	}

	var $listItems = $list.children('li');

	$styledSelect.click(function(e) {
		e.stopPropagation();
		$('div.select-styled.active').not(this).each(function(){
			$(this).removeClass('active').next('ul.select-options').slideUp();
		});
		$(this).toggleClass('active').next('ul.select-options').slideToggle();
	});

	$listItems.click(function(e) {
		e.stopPropagation();
		$styledSelect.text($(this).text()).removeClass('active');
		$this.val($(this).attr('rel'));
		$this.trigger('customSelect');
		$list.slideUp();
	});

	if(selectedVal) {
		$listItems.each(function () {
			var $selectedItem = $(this);
			if($selectedItem.attr('rel') == selectedVal) {
                $styledSelect.text($selectedItem.text()).removeClass('active');
                $this.val($selectedItem.attr('rel'));
			}
        })
	}

	$(document).click(function() {
		$styledSelect.removeClass('active');
		$list.slideUp();
	});
});
