import React, {Component} from "react";
import {render} from "react-dom";
import PropTypes from "prop-types";

import IncreaseNumber from "./IncreaseNumber";

class IncreaseNumberSlider extends Component {
    constructor(props) {
        super(props);

        this.slides = []; // will be changed after when mounting
        this.sliderInterval = null;

        this.state = {
            activeIndex: 0, // always start from zero
            postActive: false,
        };
    }

    componentDidMount() {
        let timeout = null;

        this.getCurrentActiveSlide().animate();
        this.sliderInterval = window.setInterval(() => {
            if (timeout) { window.clearTimeout(timeout); timeout = null; }
            this.getCurrentActiveSlide().reset();
            const {activeIndex} = this.state;
            this.setState({
                activeIndex: activeIndex !== this.countSlides() - 1 ? activeIndex + 1 : 0,
                postActive: false
            }, () => {
                // make this dalaying as an parameter and changable
                this.getCurrentActiveSlide().animate();
            });

            timeout = window.setTimeout(() => {
                this.setState({postActive: true});
            }, this.props.slidingInterval - this.props.fadingDuration);
        }, this.props.slidingInterval);
    }

    componentWillUnmount() {
        window.clearInterval(this.sliderInterval);
    }

    countSlides() {
        return this.props.slides.length;
    }

    getCurrentActiveSlide() {
        return this.refs[`slide_${this.state.activeIndex}`];
    }

    getSliderInnerLeft() {
        return `${this.state.activeIndex * -100}%`;
    }

    getSlides() {
        return this.slides = this.props.slides.map((slideProps, index) => {
            return (
                <div
                    key={index}
                    className={`IncreaseNumberSlider__slide ${this.state.activeIndex === index ? 'IncreaseNumberSlider__slide--active' : ''} ${this.state.activeIndex === index && this.state.postActive ? 'IncreaseNumberSlider__slide--post-active' : ''}`}
                    style={{transitionDuration: `${this.props.fadingDuration}ms`}}
                >
                    <IncreaseNumber {...slideProps} ref={`slide_${index}`} />
                </div>
            );
        });
    }

    render() {
        // TODO : jQuery's SameHeightElem class should not be mixed with React components, try to do the same height with a better and cleaner way (how about display table cell or flex?)
        return (
            <div className="IncreaseNumberSlider">
                <div
                    className="IncreaseNumberSlider__inner SameHeightElem"
                    style={{left: this.getSliderInnerLeft()}}
                >
                    {this.getSlides()}
                </div>
            </div>
        )
    }



}

export default IncreaseNumberSlider;

IncreaseNumberSlider.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object).isRequired,
    slidingInterval: PropTypes.number,
    fadingDuration: PropTypes.number,
};

IncreaseNumberSlider.defaultProps = {
    slidingInterval: 5000, // ms
    fadingDuration: 500,
}

$('[data-component=IncreaseNumberSlider]').each(function() {
    const $node = $(this);
    const params = $node.data('params');
    render(<IncreaseNumberSlider {...params} />, this);
});
