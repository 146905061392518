import React, {Component, Fragment} from "react";
import {render} from "react-dom";
import Validation from "fv-validation";

class SubscribeForm extends Component {

    constructor(props) {
        super(props);

        this.listeners = [];

        this.container = document.querySelector('#container');
        this.headerSection = document.querySelector('.HeaderSection');
        this.showMobileThreshold = -188;

        this.validation = new Validation();

        // Add subscriptions rule
        Validation.addRule('subscriptions', (inputs) => ({
            passed: this.validateSubscriptions(inputs),
            error: `Please select at least one option`
        }));

        this.state = {
            form: {
                firstName: '',
                lastName: '',
                email: '',
                subscriptions: {
                    news: true,
                    results: true,
                    announcements: true
                },
                privacy: false,
                terms: false,
            },
            open: 'closed',
            loading: false,
            messageSent: false,
            displayResults: false,
            internalError: false,
            errors: {},
        };

        this.focusInput = this.focusInput.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeToggle = this.onChangeToggle.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    validateSubscriptions = inputs => {
        for (let key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key]) return true;
        }
        return false;
    };

    componentDidMount() {
        this.checkLayouts();
        this.container && this.container.addEventListener('scroll', this.checkLayouts);
    }

    componentWillUnmount() {
        this.container && this.container.removeEventListener('scroll', this.checkLayouts);
    }

    checkLayouts = () => {
        if (this.headerSection && this.headerSection.getBoundingClientRect().top > this.showMobileThreshold && window.innerWidth < 840) {
            this.refs.form.style.opacity = 0;
            this.refs.form.style.visibility = 'hidden';
            this.refs.toggle.style.cursor = 'default';

        } else {
            this.refs.form.style.opacity = 1;
            this.refs.form.style.visibility = 'visible';
            this.refs.toggle.style.cursor = 'pointer';
        }
    };

    onChange(event) {
        const {form} = this.state;
        form[event.target.name] = event.target.value;
        this.setState({form});
    }

    onChangeToggle(event) {
        const {form} = this.state;
        form[event.target.name][event.target.value] = !form[event.target.name][event.target.value];
        this.setState({form});
    }
    onChangeCheckbox(event){
        const {form} = this.state;
        form[event.target.name] = !form[event.target.name];
        this.setState({form});
    }

    focusInput(event) {
        event.target.previousSibling.focus();
    }

    onSubmit(event) {
        event.preventDefault();

        const {form} = this.state;

        const messages = {
            'firstName': {
                'required': 'Your first name is required'
            },
            'lastName': {
                'required': 'Your last name is required'
            },
            'email': {
                'required': 'Your email is required',
                'email': 'Your email is invalid, please provide a valid email address'
            },
            'privacy': {
                'checked': 'The Privacy Statement must be accepted'
            },
            'terms': {
                'checked': 'The Terms and Conditions must be accepted'
            },
        };

        const validator = this.validation.validate(this.state.form, {
            lastName: 'required',
            firstName: 'required',
            email: 'required|email',
            subscriptions: 'subscriptions',
            privacy: 'checked',
            terms: 'checked',
        }, messages);

        let errors = {};

        for (let key in form) {
            if (form.hasOwnProperty(key) && validator.getError(key) !== '') {
                errors[key] = validator.getError(key);
            }
        }
        this.setState({errors: errors});

        if (!Array.isArray(validator.errors) || !validator.errors.length) {
            const request = {
                lastName: this.state.form.lastName,
                firstName: this.state.form.firstName,
                email: this.state.form.email,
                subscriptions: this.state.form.subscriptions,
                privacy: this.state.form.privacy,
                terms: this.state.form.terms,
            };

            this.setState({loading: true});

            fetch(window.APP.url + '/api/subscribe', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': window.APP.csrf_token
                },

                body: JSON.stringify(request)
            })
                .then(response => {
                    response.json().then(data => {
                        if (response.status === 422) {
                            this.setState({errors: data.errors, loading: false})
                        }
                        else if (response.status === 201) {
                            this.setState({displayResults: true, messageSent: true, loading: false});
                        } else {
                            this.setState({displayResults: true, internalError: true, loading: false});
                        }
                    });
                })
                .catch(function (error) {
                    console.log('Request failed', error);
                });
        }
    }

    togglePopup = () => {
        this.state.open === 'closed' ? this.setState({open: 'opened'}) : this.setState({open: 'closed'});
    };

    closePopup = () => {
        this.setState({open: 'closed'});
    };

    render() {
        return (
            <div className="Enquire-form" ref="form">
                <div id="fixed_cta" className={this.state.open}>
                    <div id="fixed_cta_backdrop" onClick={this.closePopup}></div>
                    <div id="fixed_cta_modal" className={`${this.state.messageSent ? 'no-scroll' : ''}`}>
                        <div id="fixed_cta_toggle" className={`toggle_cta ${this.props.hideToggle ? 'hide' : ''}`}
                             onClick={this.togglePopup} ref="toggle">
                            <div className="buttonWrapper">
                                <div className="fixed_cta_cross">
                                    <i className="fa fa-chevron-left"></i>
                                    <i className="fa fa-times"></i>
                                </div>
                                <p>Subscribe</p>
                            </div>
                        </div>
                        <div id="fixed_cta_content_area"
                             style={this.state.displayResults ? {paddingBottom: '50px'} : {}}>
                            {this.state.displayResults && this.state.messageSent &&
                            <Fragment>
                                <svg id="successAnimation" className="checkmark" viewBox="0 0 52 52">
                                    <circle className="checkmark-circle" fill="none" cx="26" cy="26" r="25"/>
                                    <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                </svg>
                                <div id="successMessage">Thank you for your request. You are now subscribed to our
                                    latest news.
                                </div>
                            </Fragment>
                            }
                            {this.state.displayResults && this.state.internalError &&
                            <div className="errorContainer">
                                <div id="errorMessage">An internal error has occured. Please try again later.</div>
                            </div>
                            }


                            {!this.state.displayResults && !this.state.messageSent &&
                            <form id="fixed_cta_form" onSubmit={this.onSubmit}>
                                <h3 className="mrgT0">Please fill in your details</h3>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <div className="form_input mrgT30">
                                            <input
                                                name="firstName"
                                                type="text"
                                                title="First name"
                                                onChange={this.onChange}
                                                className={`${this.state.form.firstName !== '' ? 'has_value' : ''}`}
                                                tabIndex={1}
                                            />
                                            <small onClick={this.focusInput}>First name*</small>
                                            {this.state.errors.hasOwnProperty('firstName') ? (
                                                <div className="error_message error_message--show">
                                                    {this.state.errors.firstName}
                                                </div>
                                            ) : (
                                                <div className="error_message"></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <div className="form_input mrgT30-md">
                                            <input
                                                name="lastName"
                                                type="text"
                                                title="Last name"
                                                onChange={this.onChange}
                                                className={`${this.state.form.lastName !== '' ? 'has_value' : ''}`}
                                                tabIndex={2}
                                            />
                                            <small onClick={this.focusInput}>Last name*</small>
                                            {this.state.errors.hasOwnProperty('lastName') ? (
                                                <div className="error_message error_message--show">
                                                    {this.state.errors.lastName}
                                                </div>
                                            ) : (
                                                <div className="error_message"></div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form_input">
                                            <input
                                                name="email"
                                                type="text"
                                                title="Email"
                                                onChange={this.onChange}
                                                className={`${this.state.form.email !== '' ? 'has_value' : ''}`}
                                                tabIndex={7}
                                            />
                                            <small onClick={this.focusInput}>Email*</small>
                                            {this.state.errors.hasOwnProperty('email') ? (
                                                <div className="error_message error_message--show">
                                                    {this.state.errors.email}
                                                </div>
                                            ) : (
                                                <div className="error_message"></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mrgT50">What would you like to subscribe to?</h3>

                                <div className="row mrgT30">
                                    <div className="col-xs-12 toggle_container">

                                        <div className="toggle">
                                            <span className="toggle__label">News</span>
                                            <label className="switch">
                                                <input type="checkbox" value="news" name="subscriptions"
                                                       onChange={this.onChangeToggle}
                                                       checked={this.state.form.subscriptions.news}/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>

                                        <div className="toggle">
                                            <span className="toggle__label">Results</span>
                                            <label className="switch">
                                                <input type="checkbox" value="results" name="subscriptions"
                                                       onChange={this.onChangeToggle}
                                                       checked={this.state.form.subscriptions.results}/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>

                                        <div className="toggle">
                                            <span className="toggle__label">Regulatory announcements</span>
                                            <label className="switch">
                                                <input type="checkbox" value="announcements" name="subscriptions"
                                                       onChange={this.onChangeToggle}
                                                       checked={this.state.form.subscriptions.announcements}/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        {this.state.errors.hasOwnProperty('subscriptions') ? (
                                            <div className="error_message error_message--show">
                                                {this.state.errors.subscriptions}
                                            </div>
                                        ) : (
                                            <div className="error_message"></div>
                                        )}
                                    </div>
                                </div>

                                <div className="row mrgT20">
                                    <div className="col-xs-12">
                                        <input type="checkbox"
                                               id="privacyCheckbox"
                                               value={this.state.privacy}
                                               name="privacy"
                                               onChange={this.onChangeCheckbox}
                                               checked={this.state.form.privacy}/>
                                        <label htmlFor="privacyCheckbox" className="bottomText mrgT20 mrgB0 mrgL10">I have read and agree to
                                            the <a href={url('/downloads/PayPoint_Privacy_Notice_-_PayPoint_Notification_Service.pdf')} target="_blank">Privacy Statement</a></label>
                                    </div>
                                </div>

                                <div className="row mrgT10">
                                    <div className="col-xs-12">
                                        <input type="checkbox"
                                               id="termsCheckbox"
                                               value={this.state.terms}
                                               name="terms"
                                               onChange={this.onChangeCheckbox}
                                               checked={this.state.form.terms}/>
                                        <label htmlFor="termsCheckbox" className="bottomText mrgB0 mrgL10">I have read and agree to
                                            the <a href={url('/downloads/PayPoint_Notification_Service_-_Website_Terms_-_December_2018.pdf')} target="_blank">Terms and Conditions</a></label>
                                        {this.state.errors.hasOwnProperty('privacy') ? (
                                            <div className="error_message error_message--show mrgL15 mrgT0">
                                                {this.state.errors.privacy}
                                            </div>
                                        ) : (
                                            <div className="error_message"></div>
                                        )}
                                        {this.state.errors.hasOwnProperty('terms') ? (
                                            <div className={`error_message error_message--show mrgL15 ${this.state.errors.hasOwnProperty('privacy') ? ' mrgT20' : ''}`}>
                                                {this.state.errors.terms}
                                            </div>
                                        ) : (
                                            <div className="error_message"></div>
                                        )}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form_input">
                                            <button className={`button ${this.state.loading ? 'loading' : ''}`}>
                                                Subscribe
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const nodes = document.querySelectorAll('.component-subscribe-form');
for (let i = 0; i < nodes.length; i++) {
    const props = JSON.parse(nodes[i].getAttribute('data-props') || '{}');
    render(<SubscribeForm {...props}/>, nodes[i]);
}

