$(document).ready(function() {

    const $selects = $("#ResultsSelect, #TypesSelect");
	const $select = $("#ResultsSelect");
    const $typeSelect = $("#TypesSelect");
	const $table = $("#Results");

	let options = [];
	let typesOptions = [];

	$select.find('option').each(function() {
		let val = $(this).attr('value');
		if(val !== 'all') {
			options.push(val);
		}
	});

    $typeSelect.find('option').each(function() {
        let val = $(this).attr('value');
        if(val !== 'all') {
            typesOptions.push(val);
        }
    });

	$selects.on('customSelect', function (e) {
		let year = $select.val();
        let type = $typeSelect.val();

        if ($('.meeting').length > 0) {
            type = 'all';
        }

		if(options.indexOf(year) > -1 && typesOptions.indexOf(type) > -1) {
			$table.find('tbody tr').each(function() {
				$(this).hide();
			});

			$table.find('tbody tr.Year-'+year+'.Type-'+type).each(function() {
				$(this).show();
			});
		}

		if(year === 'all') {
			if(type === 'all')
			{
                $table.find('tbody tr').each(function() {
                    $(this).show();
                });
			}else{
                $table.find('tbody tr').each(function() {
                    $(this).hide();
                });
                $table.find('tbody tr.Type-'+type).each(function() {
                    $(this).show();
                });
			}

		}
        if(type === 'all') {
            if(year === 'all')
            {
                $table.find('tbody tr').each(function() {
                    $(this).show();
                });
            }else{
                $table.find('tbody tr').each(function() {
                    $(this).hide();
                });
                $table.find('tbody tr.Year-'+year).each(function() {
                    $(this).show();
                });
            }

        }
	});
});
